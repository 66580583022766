import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { get } from "lodash";
import axios from "axios";

const AuthenticatedRoute: React.FC = () => {
  const [busy, setBusy] = React.useState(true);
  const [adminUser, setAdminUser] = React.useState<any>(null);

  React.useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios({
          url: `${process.env.REACT_APP_BACKEND_URL}/admin/getSession`,
          method: "GET",
          withCredentials: true,
        });
        setAdminUser(response.data);
        sessionStorage.setItem("adminUser", JSON.stringify(response.data));
      } catch (error) {
        console.log(error);
      } finally {
        setBusy(false);
      }
    };
    fetchUser();
  }, []);

  if (busy) {
    return <div>loading....</div>;
  }

  if (adminUser && adminUser.message === "Success") {
    return <Outlet />;
  }

  if (!adminUser || adminUser?.message !== "Success")
    return <Navigate to={{ pathname: "/login" }} />;

  return <div></div>;
};

export default AuthenticatedRoute;
