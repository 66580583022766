import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Form, Formik, useField } from "formik";
import * as Yup from "yup";
import {
  Button,
  Box,
  Flex,
  Spinner,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  Heading,
  DrawerHeader,
  Spacer,
  IconButton,
  Stack,
  Image,
  CardBody,
  CardHeader,
  Card,
  Alert,
  AlertTitle,
  DrawerBody,
  DrawerFooter,
  useToast,
  Text,
  Center,
  HStack,
  Tooltip,
} from "@chakra-ui/react";
import { FormField } from "../formField/FormField";
import { Link } from "react-router-dom";
import { get } from "lodash";
import { MdModeEditOutline } from "react-icons/md";
import ImageFormField from "../ImageFormField/ImageFormField";
import QRCode from "react-qr-code";
import { ColorPickerFormField } from "../formField/ColorPickerFormField";
import { CopyAll } from "@mui/icons-material";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const addSchema = Yup.object({
  fullName: Yup.string().required("Required"),
  profession: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  phoneNumber: Yup.string()
    // .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  company: Yup.string().required("Required"),
  webSiteLink: Yup.string().required("Required"),
  profilePic: Yup.string().required("Required"),
  logoPic: Yup.string().required("Required"),
  googleLogoPic: Yup.string().required("Required"),
  labelColor: Yup.string().required("Required"),
  backgroundColor: Yup.string().required("Required"),
  foregroundColor: Yup.string().required("Required"),
});

interface Props {
  propsUser?: any;
  isOpen: any;
  onClose: any;
  masterUser: any;
  setResponse: any;
  propsResponse: any;
}

const UserViewDrawer: React.FC<Props> = ({
  propsUser,
  isOpen,
  onClose,
  masterUser,
  setResponse,
  propsResponse,
}) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState({} as any);
  const [isDisabled, setDisabled] = useState(!!(propsUser && propsUser._id));
  const [message, setMessage] = useState("");
  const [isDuplicate, setIsDuplicate] = useState(false);

  const toast = useToast();

  const initialValue = useMemo(() => {
    if (propsUser) {
      return {
        fullName: propsUser.fullName,
        profession: propsUser.profession,
        email: propsUser.email,
        phoneNumber: propsUser.phoneNumber,
        company: propsUser.company,
        webSiteLink: propsUser.webSiteLink,
        profilePic: propsUser.profilePic,
        logoPic: propsUser.logoPic,
        googleLogoPic: propsUser.googleLogoPic,
        labelColor: propsUser.labelColor,
        backgroundColor: propsUser.backgroundColor,
        foregroundColor: propsUser.foregroundColor,
      };
    }
    return {
      fullName: "",
      profession: "",
      email: "",
      phoneNumber: "",
      company: "",
      webSiteLink: "",
      profilePic: "",
      logoPic: "",
      googleLogoPic: "",
      labelColor: "#ffffff",
      backgroundColor: "#000000",
      foregroundColor: "#ffffff",
    };
  }, [propsUser]);

  const onSubmit = (values: any) => {
    setLoading(true);
    if (!propsUser || isDuplicate) {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/user`,
        data: values,
        withCredentials: true,
      })
        .then(function (response) {
          toast({
            title: "Success.",
            description: "User is created.",
            status: "success",
            isClosable: true,
            position: "top-right",
          });

          onClose();
          setResponseData(response.data);
          setResponse([
            {
              _id: response?.data?.user?._id,
              key: 1,
              Index: 1,
              fullName: response?.data?.user?.fullName
                ? response?.data?.user?.fullName
                : "-",
              profession: response?.data?.user?.profession
                ? response?.data?.user?.profession
                : "-",
              email: response?.data?.user?.email
                ? response?.data?.user?.email
                : "-",
              company: response?.data?.user?.company
                ? response?.data?.user?.company
                : "-",
              phoneNumber: response?.data?.user?.phoneNumber
                ? response?.data?.user?.phoneNumber
                : "-",
              webSiteLink: response?.data?.user?.webSiteLink
                ? response?.data?.user?.webSiteLink
                : "-",
              profilePic: response?.data?.user?.profilePic || "",
              logoPic: response?.data?.user?.logoPic || "",
              googleLogoPic: response?.data?.user?.googleLogoPic || "",
              labelColor: response?.data?.user?.labelColor || "",
              backgroundColor: response?.data?.user?.backgroundColor || "",
              foregroundColor: response?.data?.user?.foregroundColor || "",
            },
            ...propsResponse.map((value: any, index: number) => {
              return {
                ...value,
                key: index + 2,
                Index: index + 2,
              };
            }),
          ]);
          setSuccess(true);
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
          setMessage(error?.response?.data?.details?.[0].message);
          toast({
            title: "Error.",
            description: error?.response?.data?.details?.[0].message,
            status: "error",
            isClosable: true,
            position: "top-right",
          });
        });
    } else {
      axios({
        method: "patch",
        url: `${process.env.REACT_APP_BACKEND_URL}/user/${propsUser._id}`,
        data: values,
        withCredentials: true,
      })
        .then(function (response) {
          toast({
            title: "Success.",
            description: "User is updated.",
            status: "success",
            isClosable: true,
            position: "top-right",
          });
          onClose();
          setResponseData(response.data);
          setResponse([
            ...propsResponse.map((value: any, index: number) => {
              if (value._id === response?.data?.user?._id) {
                return {
                  _id: response?.data?.user?._id,
                  key: 1,
                  Index: 1,
                  fullName: response?.data?.user?.fullName
                    ? response?.data?.user?.fullName
                    : "-",
                  profession: response?.data?.user?.profession
                    ? response?.data?.user?.profession
                    : "-",
                  email: response?.data?.user?.email
                    ? response?.data?.user?.email
                    : "-",
                  company: response?.data?.user?.company
                    ? response?.data?.user?.company
                    : "-",
                  phoneNumber: response?.data?.user?.phoneNumber
                    ? response?.data?.user?.phoneNumber
                    : "-",
                  webSiteLink: response?.data?.user?.webSiteLink
                    ? response?.data?.user?.webSiteLink
                    : "-",
                  profilePic: response?.data?.user?.profilePic || "",
                  logoPic: response?.data?.user?.logoPic || "",
                  googleLogoPic: response?.data?.user?.googleLogoPic || "",
                  labelColor: response?.data?.user?.labelColor || "",
                  backgroundColor: response?.data?.user?.backgroundColor || "",
                  foregroundColor: response?.data?.user?.foregroundColor || "",
                };
              }

              return value;
            }),
          ]);
          setSuccess(true);
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
          setMessage(error?.response?.data?.details?.[0].message);
          toast({
            title: "Error.",
            description: error?.response?.data?.details?.[0].message,
            status: "error",
            isClosable: true,
            position: "top-right",
          });
        });
    }
  };

  const users = useMemo(() => {
    return masterUser;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (propsUser) {
      const tmpUser = masterUser.find((ml: any) => ml._id === propsUser._id);
      if (tmpUser !== propsUser) {
      }
    } else if (users.length !== masterUser.length) {
      onClose();
    }
  }, [masterUser, users, onClose, propsUser, toast]);

  return (
    <Box position={"relative"}>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        height={"90vh"}
        width={"100vw"}
      >
        <Formik
          initialValues={initialValue}
          validationSchema={addSchema}
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            submitForm,
            resetForm,
            values,
            setFieldValue,
          }) => (
            <Form>
              <Drawer
                isOpen={isOpen}
                placement="right"
                size={"xl"}
                onClose={onClose}
                closeOnOverlayClick={loading}
              >
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerHeader
                    backgroundColor={"gray.100"}
                    alignItems={"stretch"}
                  >
                    <Flex>
                      {isDuplicate
                        ? `Duplicate User ( ${get(propsUser, "email")} )`
                        : get(propsUser, "email")
                        ? isDisabled
                          ? "View User"
                          : "Edit User"
                        : "Add User"}
                      <Spacer></Spacer>
                      {propsUser && propsUser._id && (
                        <Tooltip label={`Duplicate user`}>
                          <IconButton
                            aria-label="duplicate user"
                            key={"duplicateUserButton"}
                            alignSelf={"end"}
                            isDisabled={loading || !isDisabled}
                            onClick={() => {
                              // setDisabled(!isDisabled);s
                              setIsDuplicate(!isDuplicate);
                            }}
                            icon={<CopyAll />}
                          />
                        </Tooltip>
                      )}
                      {propsUser && propsUser._id && (
                        <Tooltip label={`Update user`}>
                          <IconButton
                            aria-label="update link"
                            key={"updateLinkButton"}
                            alignSelf={"end"}
                            isDisabled={loading || isDuplicate}
                            onClick={() => {
                              setDisabled(!isDisabled);
                            }}
                            icon={<MdModeEditOutline />}
                          />
                        </Tooltip>
                      )}
                    </Flex>

                    {message && (
                      <Alert status="error">
                        <AlertTitle>{message}</AlertTitle>
                      </Alert>
                    )}
                  </DrawerHeader>

                  <DrawerBody>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        marginTop: "-15px",
                      }}
                    >
                      <Box sx={{ width: "40%", position: "fixed" }}>
                        <Card
                          sx={{ margin: "20px 10px 0px 0px" }}
                          boxShadow="md"
                          rounded="md"
                          bg="white"
                          h="380px"
                          borderTopRadius="8px"
                        >
                          <CardHeader
                            p="0"
                            h="60%"
                            backgroundColor={values.backgroundColor}
                            borderRadius="8px 8px 0px 0px"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Box width={"60%"}>
                                {values.logoPic ? (
                                  <Image
                                    src={values.logoPic}
                                    alt="No image"
                                    borderRadius="lg"
                                    w="45px"
                                    h="45px"
                                    m="5px 0px 0px 12px"
                                  />
                                ) : null}
                              </Box>
                              <Box
                                width={"40%"}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  margin: "0px 12px 0px 0px",
                                  Width: "110px",
                                  alignItems: "flex-start",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "-webkit-box",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    WebkitLineClamp: "1",
                                    WebkitBoxOrient: "vertical",
                                    height: "22px",
                                    width: "145px",
                                    textAlign: "left",
                                  }}
                                  color={values.labelColor}
                                >
                                  {values.company}
                                </Box>
                                <Box
                                  fontSize={"19px"}
                                  color={values.foregroundColor}
                                  sx={{
                                    display: "-webkit-box",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    WebkitLineClamp: "1",
                                    WebkitBoxOrient: "vertical",
                                    height: "22px",
                                    width: "145px",
                                    textAlign: "left",
                                  }}
                                >
                                  {values.profession}
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                mt: "5px",
                                ml: "5px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                ml="12px"
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Text color={values.labelColor}>Nom</Text>
                                <Heading
                                  as="h4"
                                  fontSize={"22px"}
                                  color={values.foregroundColor}
                                  sx={{
                                    textOverflow: "ellipsis",
                                    maxWidth: "215px",
                                    overflow: "hidden",
                                    display: "-webkit-box",
                                    WebkitLineClamp: "1",
                                    WebkitBoxOrient: "vertical",
                                  }}
                                >
                                  {values.fullName}
                                </Heading>
                              </Box>
                              <Box width={"87px"} height={"85px"}>
                                {values.profilePic ? (
                                  <Image
                                    src={values.profilePic}
                                    alt=""
                                    borderRadius="lg"
                                    w="87px"
                                    h="85px"
                                    m="0px 12px 0px 0px"
                                  />
                                ) : null}
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                mt: "5px",
                                ml: "5px",
                              }}
                            >
                              <Box
                                ml="12px"
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Text
                                  color={values.labelColor}
                                  sx={{ height: "22px" }}
                                >
                                  Telephone
                                </Text>
                                <Text
                                  color={values.foregroundColor}
                                  sx={{
                                    textOverflow: "ellipsis",
                                    maxWidth: "95px",
                                    overflow: "hidden",
                                    display: "-webkit-box",
                                    WebkitLineClamp: "1",
                                    WebkitBoxOrient: "vertical",
                                    height: "22px",
                                  }}
                                >
                                  {values.phoneNumber}
                                </Text>
                              </Box>
                              <Box
                                mr="12px"
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  textAlign: "right",
                                }}
                              >
                                <Text
                                  color={values.labelColor}
                                  sx={{ height: "22px" }}
                                >
                                  Courriel
                                </Text>
                                <Text
                                  sx={{
                                    textOverflow: "ellipsis",
                                    maxWidth: "215px",
                                    overflow: "hidden",
                                    display: "-webkit-box",
                                    WebkitLineClamp: "1",
                                    WebkitBoxOrient: "vertical",
                                    height: "22px",
                                  }}
                                  color={values.foregroundColor}
                                >
                                  {values.email}
                                </Text>
                              </Box>
                            </Box>
                          </CardHeader>

                          <CardBody
                            h="40%"
                            backgroundColor={values.backgroundColor}
                            borderRadius="0px 0px 8px 8px"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "white",
                                borderRadius: "10px",
                                padding: "10px 10px 10px 10px",
                              }}
                            >
                              <QRCode value={values.webSiteLink} size={100} />
                              {/* <Text
                                sx={{
                                  textOverflow: "ellipsis",
                                  maxWidth: "100px",
                                  overflow: "hidden",
                                  display: "-webkit-box",
                                  WebkitLineClamp: "1",
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {values.webSiteLink}
                              </Text> */}
                            </Box>
                          </CardBody>
                        </Card>
                      </Box>
                      <Box sx={{ width: "60%", marginLeft: "400px" }}>
                        <Flex
                          justifyContent={"space-around"}
                          alignItems="center"
                        >
                          <Flex
                            flexFlow={"column"}
                            justifyContent={"center"}
                            alignItems="center"
                          >
                            <Text color={"black"} ml="1" mt={"2"}>
                              Profile
                            </Text>
                            <ImageFormField
                              disabled={
                                isDuplicate
                                  ? !isDuplicate || loading
                                  : isDisabled || loading
                              }
                              image={values["profilePic"]}
                              onSuccess={({ img: image }: { img: string }) => {
                                if (image) setFieldValue("profilePic", image);
                              }}
                            />
                            <Text color={"red"} ml="1" mt={"2"}>
                              {errors.profilePic
                                ? (errors.profilePic as string)
                                : ""}
                            </Text>
                          </Flex>
                          <Flex
                            flexFlow={"column"}
                            justifyContent={"center"}
                            alignItems="center"
                            ml="1"
                          >
                            <Text color={"black"} ml="1" mt={"2"}>
                              Apple Logo
                            </Text>
                            <ImageFormField
                              disabled={
                                isDuplicate
                                  ? !isDuplicate || loading
                                  : isDisabled || loading
                              }
                              image={values["logoPic"]}
                              onSuccess={({ img: image }: { img: string }) => {
                                if (image) setFieldValue("logoPic", image);
                              }}
                            />
                            <Text color={"red"} ml="1" mt={"2"}>
                              {errors.logoPic ? (errors.logoPic as string) : ""}
                            </Text>
                          </Flex>
                          <Flex
                            flexFlow={"column"}
                            justifyContent={"center"}
                            alignItems="center"
                            ml="1"
                          >
                            <Text color={"black"} ml="1" mt={"2"}>
                              Google Logo
                            </Text>
                            <ImageFormField
                              disabled={
                                isDuplicate
                                  ? !isDuplicate || loading
                                  : isDisabled || loading
                              }
                              image={values["googleLogoPic"]}
                              onSuccess={({ img: image }: { img: string }) => {
                                if (image)
                                  setFieldValue("googleLogoPic", image);
                              }}
                            />
                            <Text color={"red"} ml="1" mt={"2"}>
                              {errors.googleLogoPic
                                ? (errors.googleLogoPic as string)
                                : ""}
                            </Text>
                          </Flex>
                        </Flex>

                        <FormField
                          disabled={
                            isDuplicate
                              ? !isDuplicate || loading
                              : isDisabled || loading
                          }
                          required={true}
                          name="Full Name : "
                          fieldName="fullName"
                          type="text"
                          error={
                            touched.fullName
                              ? (errors.fullName as string)
                              : undefined
                          }
                        />
                        <FormField
                          disabled={
                            isDuplicate
                              ? !isDuplicate || loading
                              : isDisabled || loading
                          }
                          required={true}
                          name="Profession : "
                          fieldName="profession"
                          type="text"
                          error={
                            touched.profession
                              ? (errors.profession as string)
                              : undefined
                          }
                        />
                        <FormField
                          disabled={
                            isDuplicate
                              ? !isDuplicate || loading
                              : isDisabled || loading
                          }
                          required={true}
                          name="Company : "
                          fieldName="company"
                          type="text"
                          error={
                            touched.company
                              ? (errors.company as string)
                              : undefined
                          }
                        />
                        <FormField
                          disabled={
                            isDuplicate
                              ? !isDuplicate || loading
                              : isDisabled || loading
                          }
                          required={true}
                          name="Email : "
                          fieldName="email"
                          type="email"
                          error={
                            touched.email ? (errors.email as string) : undefined
                          }
                        />

                        <FormField
                          disabled={
                            isDuplicate
                              ? !isDuplicate || loading
                              : isDisabled || loading
                          }
                          required={true}
                          name="Phone Number : "
                          fieldName="phoneNumber"
                          type="text"
                          error={
                            touched.phoneNumber
                              ? (errors.phoneNumber as string)
                              : undefined
                          }
                        />
                        <FormField
                          disabled={
                            isDuplicate
                              ? !isDuplicate || loading
                              : isDisabled || loading
                          }
                          required={true}
                          name="URL : "
                          fieldName="webSiteLink"
                          type="text"
                          error={
                            touched.webSiteLink
                              ? (errors.webSiteLink as string)
                              : undefined
                          }
                        />
                        <Flex>
                          <HStack>
                            <ColorPickerFormField
                              disabled={
                                isDuplicate
                                  ? !isDuplicate || loading
                                  : isDisabled || loading
                              }
                              required={true}
                              name="Label Color : "
                              fieldName="labelColor"
                              type="color"
                              error={
                                touched.labelColor
                                  ? (errors.labelColor as string)
                                  : undefined
                              }
                              width={"90%"}
                            />
                            <ColorPickerFormField
                              disabled={
                                isDuplicate
                                  ? !isDuplicate || loading
                                  : isDisabled || loading
                              }
                              required={true}
                              name="Background Color : "
                              fieldName="backgroundColor"
                              type="color"
                              error={
                                touched.backgroundColor
                                  ? (errors.backgroundColor as string)
                                  : undefined
                              }
                              width={"90%"}
                            />
                          </HStack>
                        </Flex>

                        <ColorPickerFormField
                          disabled={
                            isDuplicate
                              ? !isDuplicate || loading
                              : isDisabled || loading
                          }
                          required={true}
                          name="Foreground Color : "
                          fieldName="foregroundColor"
                          type="color"
                          error={
                            touched.foregroundColor
                              ? (errors.foregroundColor as string)
                              : undefined
                          }
                          width={"50%"}
                        />
                      </Box>
                    </Box>
                  </DrawerBody>
                  <DrawerFooter backgroundColor={"gray.100"}>
                    <Button
                      variant="outline"
                      mr={3}
                      onClick={onClose}
                      isDisabled={loading}
                    >
                      Close
                    </Button>

                    <Button
                      colorScheme="blue"
                      onClick={submitForm}
                      isDisabled={
                        isDuplicate
                          ? !isDuplicate || loading
                          : isDisabled || loading
                      }
                    >
                      {isDuplicate
                        ? "Create Duplicate"
                        : propsUser && propsUser._id
                        ? "Update"
                        : "Save"}
                    </Button>
                  </DrawerFooter>
                </DrawerContent>
              </Drawer>
            </Form>
          )}
        </Formik>
      </Flex>
      {success && responseData && (
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"row"}
          width={"100vw"}
          marginTop={"10px"}
        >
          <Box>
            <Link
              to={responseData.googlePassURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/image/android.png"
                alt="android"
                style={{
                  height: "27px",
                  width: "auto",
                  marginRight: "10px",
                }}
              />
            </Link>
          </Box>
          <Box>
            <Link
              to={responseData.applePassURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/image/apple.png"
                style={{
                  height: "27px",
                  width: "auto",
                }}
                alt=""
              />
            </Link>
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default UserViewDrawer;
