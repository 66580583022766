import {
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Spacer,
} from "@chakra-ui/react";
import { Field } from "formik";
import React from "react";
import styles from "./FormField.module.css";
import { SketchPicker } from "react-color";

export interface FieldType {
  required: boolean;
  name: string;
  fieldName: string;
  type: string;
  disabled?: boolean;
  error: string | undefined;
  placeholder?: string;
  width: string;
}

export const ColorPickerFormField: React.FC<FieldType> = (props) => {
  return (
    <>
      <FormControl
        isRequired={props.required}
        style={{
          opacity: props.disabled ? 0.6 : 1,
          cursor: props.disabled ? "not-allowed" : "inherit",
        }}
      >
        <Flex>
          <HStack>
            <FormLabel>{props.name}</FormLabel>
            <Spacer></Spacer>
            <p className={styles.error}>{props.error}</p>
          </HStack>
        </Flex>
        <Field
          name={props.fieldName}
          type={props.type}
          className={props.error ? styles.errorField : styles.field}
        >
          {/* @ts-ignore */}
          {({ field, meta: { touched }, form }) => {
            return (
              <>
                <SketchPicker
                  className={
                    touched && props.error ? styles.errorField : styles.field
                  }
                  color={field.value}
                  onChange={(color) => {
                    form.setFieldValue(props.fieldName, color.hex);
                  }}
                  width={props.width}
                />
              </>
            );
          }}
        </Field>
      </FormControl>
    </>
  );
};
