import "./App.css";
import AuthenticatedRoute from "./components/Auth/AuthenticatedRoute";
import Sidebar from "./components/Header/Sidebar";
import Login from "./components/Login/Login";
import FormTable from "./pages/Table/FormTable";

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

function App() {
  return (
    <>
      {/* <Home /> */}
      {/* <FormTable /> */}

      <Router>
        <Routes>
          <Route
            path="/login"
            element={
              <>
                <Login />
              </>
            }
          ></Route>
          <Route
            path="/"
            element={
              <>
                <AuthenticatedRoute />
              </>
            }
          >
            <Route path="/" element={<FormTable />}></Route>
          </Route>
          <Route path="*" element={<Navigate replace to="/login" />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
