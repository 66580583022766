import {
  Alert,
  AlertIcon,
  Button,
  CloseButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  AlertTitle,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
interface Props {
  propsUser?: any;
  isOpen: any;
  onClose: any;
  masterUser: any;
  setResponse: any;
  propsResponse: any;
}
const UserDeleteConfirmation: React.FC<Props> = ({
  propsUser,
  isOpen,
  onClose,
  masterUser,
  setResponse,
  propsResponse,
}) => {
  const { _id } = propsUser;
  const [message, setMessage] = useState("");
  const [busy, setBusy] = useState(false);
  const toast = useToast();

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete User</ModalHeader>
        <ModalCloseButton disabled={busy} />
        <ModalBody>
          <Text fontWeight="bold">Are you sure to delete?</Text>
          <Text color={"red"} fontWeight="bold" mb="1rem">
            {propsUser.email}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose} disabled={busy}>
            Close
          </Button>
          <Button
            colorScheme="red"
            variant={"outline"}
            mr={3}
            disabled={busy}
            onClick={() => {
              setBusy(true);
              axios({
                method: "DELETE",
                url: `${process.env.REACT_APP_BACKEND_URL}/user/${_id}`,
                withCredentials: true,
              })
                .then(function (response) {
                  toast({
                    title: "Success.",
                    description: "User Successfully deleted..",
                    status: "success",
                    isClosable: true,
                    position: "top-right",
                  });
                  console.log(response);
                  setMessage("");
                  setBusy(false);
                  setResponse(
                    propsResponse.filter(
                      (val: Record<string, any>) => val._id !== _id
                    )
                  );
                  onClose();
                })
                .catch(function (error) {
                  toast({
                    title: "Error.",
                    description: error?.response?.data?.message,
                    status: "error",
                    isClosable: true,
                    position: "top-right",
                  });
                  console.log(error);
                  setMessage(error?.response?.data?.message);
                  setBusy(false);
                });
            }}
          >
            Submit
          </Button>
        </ModalFooter>
        {message && (
          <ModalFooter>
            <Alert status="error">
              <AlertIcon />
              <AlertTitle mr={2}>{message}</AlertTitle>
              {/* <CloseButton
                position="absolute"
                right="8px"
                top="8px"
                onClick={() => {
                  setMessage("");
                }}
              /> */}
            </Alert>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default UserDeleteConfirmation;
