import { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  CircularProgress,
  Center,
} from "@chakra-ui/react";
import axios from "axios";
import * as Yup from "yup";

import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { FormField } from "../formField/FormField";

const loginSchema = Yup.object({
  email: Yup.string().email().required("Required"),

  password: Yup.string().required(),
});
function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  let navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmit = (values: any) => {
    const payloadValue = values;

    axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/admin/login`,
      data: payloadValue,
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => {
        localStorage.setItem(
          "token",
          res.headers["X-Auth-Token"] || res.headers["x-auth-token"]
        );
        setIsSubmitting(false);

        sessionStorage.setItem("adminUser", JSON.stringify(res.data));
        navigate("../");
      })
      .catch((error) => {
        setIsSubmitting(false);
        if (error === "Error: Network Error") {
          setErrorMsg(
            "Something went wrong, please try again after some time or Refresh the Page."
          );
        } else if (error.response.status === 401) {
          setErrorMsg(error.response.data.message);
        } else if (error.response.status === 500) {
          setErrorMsg("Something happened wrong try again after sometime.");
        } else if (error.response.status === 422) {
          setErrorMsg(error.response.data.message);
        } else if (error.response.status === 415) {
          setErrorMsg(error.response.data.message);
        }
      });
  };

  return (
    <Flex minHeight="100vh" width="full" align="center" justifyContent="center">
      <Box
        borderWidth={1}
        px={4}
        width="full"
        maxWidth="500px"
        borderRadius={4}
        textAlign="center"
        boxShadow="lg"
      >
        <Box p={4}>
          <Box textAlign="center">
            <Text>
              <br></br>
            </Text>
            <Heading>Sign In to Your Account</Heading>
            <Text>
              <br></br>
            </Text>
            <Text color="red">{errorMsg}</Text>
          </Box>
          <Box my={8} textAlign="left">
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={loginSchema}
              onSubmit={onSubmit}
            >
              {({
                errors,
                touched,
                submitForm,
                resetForm,
                values,
                setFieldValue,
              }) => (
                <Form>
                  <FormField
                    disabled={isSubmitting}
                    required={true}
                    name="Email : "
                    fieldName="email"
                    type="text"
                    error={touched.email ? (errors.email as string) : undefined}
                  />{" "}
                  <FormField
                    disabled={isSubmitting}
                    required={true}
                    name="Password : "
                    fieldName="password"
                    type="password"
                    error={
                      touched.password ? (errors.password as string) : undefined
                    }
                  />
                  {isSubmitting ? (
                    <Flex
                      bg="white.500"
                      color="gery.500"
                      width="full"
                      justifyContent="center"
                      mt={4}
                    >
                      <Center>
                        <CircularProgress
                          size={7}
                          isIndeterminate
                          color="green.500"
                          m={1}
                        />
                      </Center>
                    </Flex>
                  ) : (
                    <Button
                      bg="green.500"
                      color="white"
                      width="full"
                      disabled={isSubmitting || Object.keys(errors).length > 0}
                      mt={4}
                      onClick={submitForm}
                    >
                      Sign In
                    </Button>
                  )}
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
}

export default Login;
