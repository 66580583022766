import { Row, Spin, Table } from "antd";
import axios from "axios";
import { get } from "lodash";
import { useEffect, useMemo, useState } from "react";
import "./Formtable.css";
import {
  Box,
  Button,
  Center,
  IconButton,
  Input,
  Spacer,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import UserViewDrawer from "../../components/UserViewDrawer/UserViewDrawer";
import { AiFillApple, AiOutlineGoogle } from "react-icons/ai";
import { MdViewModule } from "react-icons/md";
import { FiEye } from "react-icons/fi";
import UserDeleteConfirmation from "../../components/modals/UserDeleteConfirmation";
import { IoMdTrash } from "react-icons/io";
import { PiEyeThin } from "react-icons/pi";

const FormTable = () => {
  const [data, setData] = useState<any>([]);
  const [response, setResponse] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(1);

  const [targetItem, setTargetItem] = useState<any>();

  const toast = useToast();

  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();

  const viewItem = (item: any) => {
    setTargetItem(item);
    onViewOpen();
  };

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const deleteItem = (item: any) => {
    setTargetItem(item);
    onDeleteOpen();
  };

  useEffect(() => {
    // if (!isViewOpen) {
    setLoading(true);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/user`,
      withCredentials: true,
    })
      .then(function (response) {
        setResponse(
          response.data.filter(search1).map((value: any, index: number) => ({
            _id: value._id,
            key: index + 1,
            Index: index + 1,
            fullName: value.fullName ? value.fullName : "-",
            profession: value.profession ? value.profession : "-",
            email: value.email ? value.email : "-",
            company: value.company ? value.company : "-",
            phoneNumber: value.phoneNumber ? value.phoneNumber : "-",
            webSiteLink: value.webSiteLink ? value.webSiteLink : "-",
            profilePic: value.profilePic || "",
            logoPic: value.logoPic || "",
            googleLogoPic: value.googleLogoPic || "",
            backgroundColor: value.backgroundColor || "",
            labelColor: value.labelColor || "",
            foregroundColor: value.foregroundColor || "",
          }))
        );
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
    // }
  }, []);

  const searchColumns = useMemo(
    () => [
      "fullName",
      "profession",
      "email",
      "company",
      "phoneNumber",
      "webSiteLink",
      "profilePic",
      "logoPic",
      "googleLogoPic",
      "backgroundColor",
      "labelColor",
      "foregroundColor",
    ],
    []
  );

  const search1 = (account: Record<string, any>) => {
    return searchColumns.some((column) => {
      return (
        get(account, column, "")
          .toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) > -1
      );
    });
  };

  useEffect(() => {
    setData(
      response.filter(search1).map((value: any, index: number) => ({
        _id: value._id,
        key: index + 1,
        Index: index + 1,
        fullName: value.fullName ? value.fullName : "-",
        profession: value.profession ? value.profession : "-",
        email: value.email ? value.email : "-",
        company: value.company ? value.company : "-",
        phoneNumber: value.phoneNumber ? value.phoneNumber : "-",
        webSiteLink: value.webSiteLink ? value.webSiteLink : "-",
        profilePic: value.profilePic || "",
        logoPic: value.logoPic || "",
        googleLogoPic: value.googleLogoPic || "",
        backgroundColor: value.backgroundColor || "",
        labelColor: value.labelColor || "",
        foregroundColor: value.foregroundColor || "",
        value: value,
      }))
    );
  }, [search, response]);

  const columns = [
    {
      title: "ID",
      render: (text: string, record: any, index: number) =>
        (page - 1) * paginationSize + index + 1,
      width: 80,
    },
    {
      title: "Profile",
      dataIndex: "profilePic",
      width: 90,
      render: (value: any) => (
        <>
          <img
            src={
              value ||
              "https://storage.googleapis.com/scanne-wallet/Mindweb-Technologies-Icon.png"
            }
            alt=""
            style={{ width: "50px", height: "50px" }}
          />
        </>
      ),
    },
    {
      title: "Apple Logo",
      dataIndex: "logoPic",
      render: (value: any) => (
        <>
          <img
            src={
              value ||
              "https://storage.googleapis.com/scanne-wallet/Mindweb-Technologies-Icon.png"
            }
            alt=""
            style={{ width: "258px", height: "84px", objectFit: "contain" }}
          />
        </>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
    },
    {
      title: "Profession",
      dataIndex: "profession",
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    // },
    {
      title: "Company",
      dataIndex: "company",
    },

    {
      title: "Wallet",
      dataIndex: "value",
      render: (value: any) => (
        <>
          <Tooltip label="Click to Copy apple Pass URL">
            <Button
              style={{
                padding: 0,
              }}
              size={{ base: "sm", md: "md", lg: "lg" }}
              variant="ghost"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${
                    process.env.REACT_APP_BACKEND_URL
                  }/download/apple/${value._id.toString()}`
                );
                toast({
                  title: "Success.",
                  description: "Copied! Apple Pass URL",
                  status: "success",
                  isClosable: true,
                  position: "top-right",
                  duration: 1500,
                });
              }}
            >
              {/* <img src="https://storage.googleapis.com/scanne-wallet/button-one-line-large_2x.png" /> */}
              <AiFillApple />
            </Button>
          </Tooltip>
          <Tooltip label="Click to Copy Google Pass URL">
            <Button
              style={{
                padding: 0,
              }}
              size={{ base: "sm", md: "md", lg: "lg" }}
              variant="ghost"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${
                    process.env.REACT_APP_BACKEND_URL
                  }/download/google/${value._id.toString()}`
                );
                toast({
                  title: "Success.",
                  description: "Copied! Google Pass URL",
                  status: "success",
                  isClosable: true,
                  position: "top-right",
                  duration: 1500,
                });
              }}
            >
              {/* <img src="https://storage.googleapis.com/scanne-wallet/enCA_add_to_google_wallet_add-wallet-badge.png" /> */}
              <AiOutlineGoogle />
            </Button>
          </Tooltip>
        </>
      ),
    },

    {
      title: "ACTION",
      dataIndex: "value",
      render: (value: any) => (
        <>
          <Center>
            <IconButton
              aria-label="delete link"
              key={"viewLinkButton"}
              marginLeft={2}
              onClick={() => {
                viewItem(value);
              }}
              icon={<PiEyeThin />}
            ></IconButton>
            <IconButton
              aria-label="delete link"
              key={"deleteLinkButton"}
              marginLeft={2}
              onClick={() => {
                deleteItem(value);
              }}
              icon={<IoMdTrash />}
            ></IconButton>
          </Center>
        </>
      ),
    },
  ];

  return (
    <>
      <Box
        className="header"
        marginLeft={"30px"}
        style={{
          padding: "20px 20px",
        }}
      >
        <Row
          style={{
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img
            src="./Mindweb_Logo_Light.png"
            style={{
              width: "auto",
              height: "40px",
              objectFit: "contain",
              marginRight: "50px",
            }}
            alt="Mindweb_Logo_Light"
          ></img>
          {/* <Text fontSize="xl" mt={"4px"} fontWeight={"600"}>
            Users
          </Text> */}
        </Row>
        <Row
          style={{
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Input
            id="search"
            type="text"
            placeholder="Search..."
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <Center
            bg="#047cff"
            color="white"
            p={3}
            ml={3}
            onClick={() => {
              setTargetItem(null);
              onViewOpen();
            }}
            style={{ cursor: "pointer", borderRadius: "10px" }}
          >
            + Add User
          </Center>
        </Row>
      </Box>
      <div
        style={{
          width: "96vw",
          maxWidth: "96vw",
          //   height: "86vh",
          //   top: "8vh ",
          marginTop: "20px",
          left: "2vw",
          position: "relative",
        }}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Table
            rowKey={(record) => record._id}
            columns={columns}
            pagination={{
              onChange(current, pageSize) {
                setPage(current);
                setPaginationSize(pageSize);
              },
              // hideOnSinglePage: true,
              showSizeChanger: true,
            }}
            dataSource={data}
            loading={response.length === 0 ? true : false}
            scroll={{ y: "calc(68vh - 4em)", x: 1400 }}
            rowClassName={(record: any) => record.color}
          />
        )}
      </div>
      {isViewOpen && (
        <UserViewDrawer
          isOpen={isViewOpen}
          onClose={onViewClose}
          propsUser={targetItem}
          masterUser={response}
          setResponse={setResponse}
          propsResponse={response}
        />
      )}
      {isDeleteOpen && targetItem && (
        <UserDeleteConfirmation
          propsUser={targetItem}
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
          masterUser={response}
          setResponse={setResponse}
          propsResponse={response}
        />
      )}
    </>
  );
};

export default FormTable;
